<template>
    <v-card>
        <applicant-profile-header
            :assigned-to="assignedTo"
            :applicant="applicant"
        ></applicant-profile-header>
        <v-divider></v-divider>
        <v-card-text class="d-flex text-subtitle-2 justify-space-between">
            <applicant-profile-details
                :applicant="applicant"
                :created_at="created_at"
                :property="property"
            ></applicant-profile-details>
            <applicant-profile-application
                :products="products"
                :applicant="applicant"
            ></applicant-profile-application>
            <applicant-profile-applicants
                v-if="
                    $_.get(applicant, 'application.applicants', []).length > 1
                "
                :applicant="applicant"
            ></applicant-profile-applicants>
            <applicant-profile-program
                :student="applicant.student"
                v-if="
                    applicant.student &&
                    Object.keys(applicant.student).length > 0
                "
            >
            </applicant-profile-program>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "applicant-profile",
    components: {
        ApplicantProfileHeader: () => import("./ApplicantProfileHeader"),
        ApplicantProfileDetails: () => import("./ApplicantProfileDetails"),
        ApplicantProfileApplication: () =>
            import("./ApplicantProfileApplication"),
        ApplicantProfileApplicants: () =>
            import("./ApplicantProfileApplicants"),
        ApplicantProfileProgram: () => import("./ApplicantProfileProgram"),
    },
    props: {
        applicant: {
            type: Object,
            required: true,
        },
        created_at: {
            type: String,
            required: true,
        },
        property: {
            type: Object,
            required: true,
        },
        products: {
            type: Array,
            required: true,
        },
        assignedTo: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>