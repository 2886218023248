var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("applicant-profile-header", {
        attrs: { "assigned-to": _vm.assignedTo, applicant: _vm.applicant }
      }),
      _c("v-divider"),
      _c(
        "v-card-text",
        { staticClass: "d-flex text-subtitle-2 justify-space-between" },
        [
          _c("applicant-profile-details", {
            attrs: {
              applicant: _vm.applicant,
              created_at: _vm.created_at,
              property: _vm.property
            }
          }),
          _c("applicant-profile-application", {
            attrs: { products: _vm.products, applicant: _vm.applicant }
          }),
          _vm.$_.get(_vm.applicant, "application.applicants", []).length > 1
            ? _c("applicant-profile-applicants", {
                attrs: { applicant: _vm.applicant }
              })
            : _vm._e(),
          _vm.applicant.student && Object.keys(_vm.applicant.student).length > 0
            ? _c("applicant-profile-program", {
                attrs: { student: _vm.applicant.student }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }